.page-container{
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.left-bar{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 255px;
    height: 100%;
}

@media (prefers-color-scheme: light) {
    .left-bar{
        background-color: #f0f4f9;
    }
}


@media (prefers-color-scheme: dark) {
    .left-bar{
        background-color: #232323;
    }
}