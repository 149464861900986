.line {
    width: 100px;
    height: 4px;
    background-color: #1456be;
    position: absolute;
    animation: moveLine 2s infinite;
    z-index: 3;
}

@keyframes moveLine {
    0% {
        left: 0;
        width: 10px;
    }

    50% {
        width: 300px;
    }

    100% {
        left: 100%;
        width: 0px;
    }
}


@media (prefers-color-scheme: light) {
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }
}

@media (prefers-color-scheme: dark) {
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(39, 41, 44, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }
}

.stage-transition {
    position: absolute;
    top: 0;
    right: -100%; /* Start off-screen to the right */
    width: 100%;
    height: 100%;
    transition: right 5.5s ease-in-out; /* Smooth transition */
  }

  .stage-transition.active {
    right: 0; /* Move to its position */
  }

