.helpful_links_container {
    display: flex;
    justify-content: space-between;  
    align-items: center;    
    width: 950px;
}

.admin_login_page_button{
    background-color: #232323;
    border: none;
    border-radius: 22px;
    cursor: pointer;
    font-size: 15px;
}

.helpful_links{
    text-align: right;
}

@media (prefers-color-scheme: light){
    .admin_login_page_button{
        color: black;
    }
    .helpful_links{
        color: black;
    }
}


@media (prefers-color-scheme: dark){
    .admin_login_page_button{
        color: white;
    }
    .helpful_links{
        color: white;
    }
}