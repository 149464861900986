.search_box {
    padding: 10px;
    margin: 10px;
    border: 2px solid #747775;
    border-radius: 4px;
    outline: none;
    width: 420px;
    height: 35px; /* Use a fixed height for better control */
    margin-top: 110px; /* Fixed pixel value or adjust as needed */
    margin-right: 40px;
}

.search_box:focus {
    border-color: #0b57d0; /* Default blue focus color */
}

.search_box2 {
    padding: 10px;
    margin: 10px;
    border: 2px solid #747775;
    border-radius: 4px;
    outline: none;
    width: 420px;
    height: 35px; /* Use a fixed height for better control */
    margin-right: 40px;
}

.input-error {
    border: 2px solid #b3261e;
}

.input-error:focus {
    border-color: #b3261e; /* Maintain red border on focus when there's an error */
}

@media (prefers-color-scheme: dark) {
    .search_box {
        background-color: #1c1f20;
        color: white;
    }
    .search_box2 {
        background-color: #1c1f20;
        color: white;
    }
}