/* Card.css */
.card-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.card {
    position: relative;
    display: flex;
    border: 1px transparent;
    border-radius: 22px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* width: 68vw; */
    /* height: 52vh; */
    width: 1000px;
    height: 400px;
    margin: 16px;
    overflow: hidden;
    transition: box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.card:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.card-left {
    width: 500px;
    padding: 16px;
    flex: 1;
}

.card-right {
    width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.card-image {
    max-width: 100%;
    border-radius: 8px;
}


.botton-container {
    position: absolute;
    top: 310px;
    right: 43px;
    /*margin-top: 113px;*/
    /*margin-right: 40px;*/
    display: flex;
    justify-content: flex-end;
}

.login-text {
    font-size: 42px;
}

.text-left {
    position: absolute;
    align-items: flex-start;
    margin-top: 43px;
    margin-left: 26px;
}


/* Light mode */
@media (prefers-color-scheme: light) {
    .card {
        background-color: #ffffff;
        color: #000000;
        border-color: #ddd;
    }

    .card-left {
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: #ffffff;
        border-right: 1px transparent #ddd;
        justify-content: flex-start;
    }

    .card-right {
        background-color: #ffffff;
    }

    .text-right {
        text-align: right;
    }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
    .card {
        background-color: #1c1f20;
        color: #ffffff;
        border-color: #555;
    }

    .card-left {
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: #1c1f20;
        border-right: 1px transparent;
        justify-content: flex-start;
    }

    .card-right {
        background-color: #1c1f20;
        border-right: 1px transparent;
    }

    .text-right {
        color: white;
    }

}