@media (prefers-color-scheme: light) {
    body {
        background-color: #f0f4f9;
    }
}

@media (prefers-color-scheme: dark) {
    body {
        background-color: #27292c;
    }
}