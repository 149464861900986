.body{
    overflow-x: hidden;
}

.container{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    width: 100%;
}

.personal-info-container{
    margin-top: 50px;
    margin-left: 20%;
    /*justify-content: center;*/
}

.work-position-container{
    margin-left: 20%;
}

.row{
    display: flex;
    flex-direction: row;
}

.text-box-name {
    padding-left: 10px;
    border: 2px solid #747775;
    border-radius: 4px;
    outline: none;
    min-width: 100px;
    max-width: 300px;
    flex: 1 1 auto;
    height: 35px;
    margin-right: 20px;
    font-size: 18px;
    box-sizing: border-box;

    -webkit-appearance: none; /* Remove default browser styling */
    -moz-appearance: none;
    appearance: none;
}

.text-box-name:focus {
    border-color: #0b57d0; /* Default blue focus color */
}

.text-box-birth {
    padding-left: 10px;
    border: 2px solid #747775;
    border-radius: 4px;
    outline: none;
    min-width: 40px;
    max-width: 80px;
    flex: 1 1 auto;
    height: 35px;
    margin-right: 20px;
    font-size: 18px;
    box-sizing: border-box;

    -webkit-appearance: none; /* Remove default browser styling */
    -moz-appearance: none;
    appearance: none;
}

.text-box-birth:focus {
    border-color: #0b57d0; /* Default blue focus color */
}

.horizontal-line{
    display: flex;
    height: 1px; /* Line thickness */
    background-color: black;
    width: 35%; /* Line width */
    margin: 20px auto; /* Space above and below the line */
}

.create-user-button-placement{
    margin-left: 80%;
    margin-top: 10%;
}

@media (prefers-color-scheme: dark) {
    .text-box-name {
        border: 2px solid #747775;
        border-radius: 4px;
        outline: none;
        background-color: #1c1f20;
        color: white;
    }
    .text-box-birth {
        border: 2px solid #747775;
        border-radius: 4px;
        outline: none;
        background-color: #1c1f20;
        color: white;
    }
    .horizontal-line{
        background-color: white;
    }
}