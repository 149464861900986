.header-class {
    display: flex;
    height: 65px;
    margin-top: 0;
}

.logo {
    margin-left: 2px;
}

@media (prefers-color-scheme: light) {
    .header-class {
        background-color: #f0f4f9;
    }
}