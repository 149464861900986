  .blue_filled_button {
    background-color: #0e4eb5;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 22px;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    width: 100px;
    height: 42px;
  }
  
  .blue_filled_button:hover {
    background-color: #0c3f90;
  }

  @media (prefers-color-scheme: light){
    .blue_text_button {
      background-color: #ffffff;
      padding: 10px 20px;
      border: none;
      border-radius: 22px;
      cursor: pointer;
      outline: none;
      color: #0b57d0;
      width: 100px;
      height: 42px;
    }
  
    .blue_text_button:hover{
      background-color: #f5f8fd;
    }
  }

  @media (prefers-color-scheme: dark){
    .blue_text_button {
      background-color: #1c1f20;
      padding: 10px 20px;
      border: none;
      border-radius: 22px;
      cursor: pointer;
      outline: none;
      color: #0b57d0;
    }
    .blue_text_button:hover{
      background-color: #171c20;
    }

  }


