.pending_user_info_card_container{
    display: flex;
    flex-wrap: wrap;
}


.pending_user_info_card {
    flex: 1 1 300px; /* Grow and shrink starting at 300px width */
    max-width: 400px; /* Maximum width limit */
    height: 30vh; /* Responsive height based on viewport height */
    min-height: 200px; /* Minimum height to maintain content visibility */
    margin: 15px; /* Consistent margins for layout */
    max-height: 200px;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box; /* Padding and borders are included in the width/height */
}

.pending_user_info_card h1{
    margin-left: 18px;
    margin-top: 10px;
}

.pending_user_info_card p {
    font-size: 19px;
    margin-top: 0;
    margin-left: 18px;
}

@media (prefers-color-scheme: light) {
    .pending_user_info_card {
        background-color: #f0f4f9;
    }
}

@media (prefers-color-scheme: dark) {
    .pending_user_info_card {
        background-color: #232323;
    }
}
