.center-card-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center children horizontally */
    /*flex-wrap: wrap; !* Allow wrapping for smaller screens *!*/
    padding: 10px;
    box-sizing: border-box; /* Include padding in width calculation */
}

.center_card {
    overflow: hidden;
    position: relative;
    flex: 1 1 80%; /* Allow growing and shrinking but start at 40% of container width */
    max-width: 2100px; /* Set a max width */
    height: auto; /* Height based on content */
    border-radius: 18px;
    margin-bottom: 10px;
    margin-left: 10px;
    box-sizing: border-box; /* Include padding and border in width calculation */
}

.right-card {
    flex: 1 1 20%; /* Start at 20% of container width and allow flexible resizing */
    max-width: 600px; /* Max width to control scaling */
    height: auto; /* Height based on content */
    border-radius: 18px;
    margin-bottom: 10px;
    margin-left: 10px;
    box-sizing: border-box; /* Include padding and border in width calculation */
}



@media (prefers-color-scheme: light){
    .center_card{
        background-color: white;
    }
    .right-card{
        background-color: white;
    }

}

@media (prefers-color-scheme: dark) {
    .center_card{
        background-color: #111112;
        color: white;
    }
    .right-card{
        background-color: #111112;
        color: white;
    }
}