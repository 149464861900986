.container{
    width:100%;
    display:flex;
    flex-direction:column;
    max-height: 100%;
}

.welcome-text{
    margin-top: 200px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.search-box-container{
    display: flex;
    width: 100%;
    justify-content: center;
}

.admin_home_search-box{
    border: transparent;
    max-width: 750px;
    flex: 1 1 60%;
    height: 45px;
    border-radius: 20px;
    padding-left: 40px;
    margin-left: 20px;
    margin-right: 20px;
}

.admin_home_search-box:focus{
    border-color: transparent;
    outline: none;
}

.admin_info_card_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 150px; /* Reduced top margin for better space management */
    flex: 1 0 auto; /* Flexibility in growing and no base size, adapting to content */
}

.admin_info_card {
    flex: 1 1 300px; /* Grow and shrink starting at 300px width */
    max-width: 400px; /* Maximum width limit */
    height: 30vh; /* Responsive height based on viewport height */
    min-height: 200px; /* Minimum height to maintain content visibility */
    margin: 10px; /* Consistent margins for layout */
    max-height: 400px;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box; /* Padding and borders are included in the width/height */
}

.admin_info_card h1 {
    font-size: 2vw; /* Adjusts size based on the width of the viewport */
}

.admin_info_card p {
    font-size: 5vw;
    margin-top: 5px; /* Keeps the margin as specified */
}

@media (prefers-color-scheme: light) {
    .admin_home_search-box{
        background-color: #e9eef6;
    }
    .admin_home_search-box:focus{
        background-color: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .admin_info_card{
        background-color: #f0f4f9;
    }
}

@media (prefers-color-scheme: dark) {
    .admin_home_search-box{
        background-color: #323537;
        color: white;
    }
    .admin_home_search-box:focus{
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
    }
    .admin_info_card{
        background-color: #232323;
    }
}