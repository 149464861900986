.left-bar-buttons {
    outline: none;
    border: none;
    border-radius: 22px;
    cursor: pointer;
    width: 216px;
    height: 40px;
    align-content: center;
    margin-left: 15px;
    text-align: left;
    padding-left: 18px;
}

.left-bar-buttons-selected {
    outline: none;
    border: none;
    border-radius: 22px;
    cursor: pointer;
    width: 216px;
    height: 40px;
    background-color: #baddf4;
    margin-left: 14px;
    text-align: left;
    padding-left: 18px;
    font-size: 15px;
    font-weight: bold;
}

.left-bar-create-button {
    outline: none;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    width: 95px;
    height: 55px;
    margin-left: 14px;
    margin-top: 14px;
    margin-bottom: 20px;
}

@media (prefers-color-scheme: light) {
    .left-bar-buttons {
        color: black;
        background-color: #f0f4f9;
    }

    .left-bar-buttons:hover {
        background-color: #e0e1e4;
    }

    .left-bar-create-button {
        background-color: #ffffff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .left-bar-create-button:hover {
        background-color: #dee9fa;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    }
}

@media (prefers-color-scheme: dark) {
    .left-bar-buttons {
        color: #73c8ec;
        background-color: #232323;
    }

    .left-bar-buttons:hover {
        background-color: #2b2b2b;
    }

    .left-bar-create-button {
        color: white;
        background-color: #36383a;
    }

    .left-bar-create-button:hover {
        background-color: #4a4d50;
    }
}