.portal_loading_line{
    position: absolute;
    width: 200px;
    height: 4px;
    background-color: #1456be;
    z-index: 3;
    animation: moveLine 2s infinite;
}


@keyframes moveLine {
    0% {
        left: 0;
        width: 10px;
    }

    50% {
        width: 300px;
    }

    100% {
        left: 100%;
        width: 10px;
    }
}
